a, a:visited {
    color: white;
}

.episode-item {
    background-color: #111111;
    border: 2px solid #222222;
    border-radius: 12px;
    margin: 20px;
    overflow: hidden;
    padding: 0px;
}

.episode-image {
    width: 100%;
}

.episode-name {
    font-size: 14px;
    font-weight: bold;
    padding-top: 8px;
    padding-bottom: 9px;
    padding-left: 16px;
    padding-right: 16px;
}

.episode-synopsis {
    font-size: 13px;
    padding-bottom: 12px;
    padding-left: 16px;
    padding-right: 16px;
}