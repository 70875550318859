.series-summary-large-container {
    height: auto;
    overflow: hidden;
    position: relative;
}

.series-summary-large-poster-container {
    display: inline-block;
    left: 40%;
    position: relative;
    right: 0;
    top: 0;
    width: 60%;
}

.series-summary-large-gradient-overlay {
    background: linear-gradient(to right, black, transparent);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 20%;
    z-index: 10;
}

.series-summary-large-poster-image {
    height: auto;
    width: 100%;
    z-index: 0;
}

.series-summary-large-details-overlay {
    height: 100vh;
    left: 40px;
    position: absolute;
    top: 30px;
    width: 40vw;
    z-index: 10;
}

.series-summary-large-series-title {
    color: white;
    font-size: 3vw;
    font-weight: bold;
}

.series-summary-large-series-synopsis {
    color: white;
    font-size: 1.8vw;
    font-weight: normal;
    margin-top: 20px;
    width: 35vw;
}