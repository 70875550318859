.series-large {
    display: block;
}

.series-small {
    display: none;
}

@media (max-width: 767px) {

    .series-large {
        display: none;
    }
    
    .series-small {
        display: block;
    }

}

.series-container {
    height: 100vh;
    width: 100vw;
}

.series-back-button {
    position: absolute;
    top: 16px;
    left: 20px;
    cursor: pointer;
    z-index: 10;
    transition: opacity 0.5s ease;
    width: 18px;
    opacity: 1;
  }
  
  .series-container .series-back-button {
    opacity: 1;
  }

  .series-season-dropdown {
    margin-left: 6x;
    margin-top: 12px;
    width: 100%;
  }