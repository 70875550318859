.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #121212;
    /* Dark mode background */
    color: white;
}

.splash-logo {
    width: 50vw;
    margin-bottom: 60px;
}

.label {
    font-size: 6vw;
    margin-bottom: 50px;
}

.input-container {
    display: flex;
    gap: 10px;
    padding-bottom: 180px;
}

.input-field {
    width: 15vw;
    height: 15vw;
    font-size: 10vw;
    font-weight: bold;
    text-align: center;
    border: 3px solid #333;
    border-radius: 8px;
    color: white;
    background-color: #333;
}

.input-field:focus {
    outline: none;
    border-color: #555;
}

/* Hide the up and down arrows on number inputs */
.input-field::-webkit-inner-spin-button,
.input-field::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.input-field {
    -moz-appearance: textfield;
    /* Firefox */
}