
.header-logo-image {
    margin: 12px;
    max-height: 30px;
}

.header-wrapper {
    align-items: center;
    display: flex;
    height: auto;
    justify-content: center;
    width: 100%;
}
