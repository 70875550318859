.intro-large {
    display: block;
}

.intro-small {
    display: none;
}

@media (max-width: 767px) {

    .intro-large {
        display: none;
    }
    
    .intro-small {
        display: block;
    }

}

.intro-container {
    height: 100vh;
    width: 100vw;
}

.intro-back-button {
    position: absolute;
    top: 16px;
    left: 20px;
    cursor: pointer;
    z-index: 10;
    transition: opacity 0.5s ease;
    width: 18px;
    opacity: 1;
  }
  
  .intro-container .intro-back-button {
    opacity: 1;
  }