.summary-fade {
    transition: opacity 1s ease;
    /* This enables the fading effect */
    opacity: 1;
    /* Initial opacity set to fully visible */
    position: relative;
    width: 100%;
    /* Full width of its parent */
    padding-top: 56.25%;
    /* Maintain the aspect ratio */
    margin-bottom: 16px;
    /* Maintain the rest of your styles for this container */
}

/* Media query to hide info on mobile */
@media (min-width: 767px) {

    .summary-fade {
        padding-top: 34.25%
    }

}


.browse-header-large {
    display: block;
}

.browse-header-small {
    display: none;
}

@media (max-width: 767px) {

    .browse-header-large {
        display: none;
    }
    
    .browse-header-small {
        display: block;
    }

}







