.series-summary-small-container {
    height: auto;
    overflow: hidden;
    position: relative;
}

.series-summary-small-poster-container {
    display: inline-block;
    left: 0;
    position: relative;
    right: 0;
    top: 0;
    width: 100%;
}

.series-summary-small-poster-image {
    height: auto;
    width: 100%;
    z-index: 0;
}
