.viewer-container {
    display: flex;
    vertical-align: middle;
    height: 100vh;
    width: 100vw;
}

.back-button {
    position: absolute;
    top: 16px;
    left: 20px;
    cursor: pointer;
    z-index: 10;
    transition: opacity 0.5s ease;
    width: 18px;
    opacity: 1;
  }
  
  .viewer-container .back-button {
    opacity: 1;
  }
  