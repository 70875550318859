
.season-menu {
    margin-left: 8px;
    position: relative;
    width: 220px;
  }
  
  .season-menu-arrow-down {
    height: 11px;
    width: 11px;
    margin-right: 12px;
  }
  
  .season-menu-option {
    color: #ffffff;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
  }
  
  .season-menu-option:hover {
    background-color: #3f3f3f;
  }
  
  .season-menu-options {
    background-color: #111111;
    border: 1px solid #181818;
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
    box-shadow: 0px 4px 10px #222222;
    padding-bottom: 6px;
    padding-top: 2px;
    position: absolute;
    top: 100%;
    width: 239px;
    z-index: 1000;
  }
  
  .season-menu-selected-value {
    align-items: center;
    color: #FFFFFF;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    font-weight: bold;
    height: 40px;
    justify-content: space-between;
    padding-left: 19px;
    width: 220px;
  }
  
  .season-menu-selected-value-open {
    align-items: center;
    background-color: #111111;
    border: 1px solid #181818;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    color: #999999;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    font-weight: bold;
    height: 40px;
    justify-content: space-between;
    padding-left: 19px;
    width: 220px;
  }